import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"https://connect.facebook.net/de_DE/sdk.js#xfbml=1&appId=272376829538322&version=v2.0\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <Header></Header>
        <div id="meganav">
          <div className="container">
            {" "}
            <a
              href="/"
              title="bebe Zartpflege®"
              target="_self"
              className="navbar-brand"
            >
              <em>
                bebe Zartpflege<sup>®</sup>
              </em>
            </a>
            <div className="close-meganav" />
            <div className="col-xs-12 meganav-top">
              {" "}
              <a
                href="/"
                target="_self"
                title="Start"
                className="navitem"
              >
                Start
              </a>{" "}
              <a
                href="/menu/kontakt/"
                target="_self"
                title="Kontakt"
                className="navitem"
              >
                Kontakt
              </a>{" "}
              <a
                href="/menu/faq/"
                target="_self"
                title="FAQ"
                className="navitem"
              >
                FAQ
              </a>{" "}
              <a
                href="/menu/impressum/"
                target="_self"
                title="Impressum"
                className="navitem"
              >
                Impressum
              </a>{" "}
              <a
                href="/menu/rechtliche-hinweise/"
                target="_self"
                title="Rechtliche Hinweise"
                className="navitem"
              >
                Rechtliche Hinweise
              </a>{" "}
              <a
                href="/menu/cookie-information/"
                target="_self"
                title="Cookies"
                className="navitem"
              >
                Cookies
              </a>{" "}
              <a
                href="/menu/allg-gewinnspielbedingungen/"
                target="_self"
                title="Allg. Gewinnspielbedingungen"
                className="navitem"
              >
                Allg. Gewinnspielbedingungen
              </a>{" "}
            </div>
            <div id="col-products" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/produkte/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.produkte.png"
                  alt="Produkte"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/produkte/"
                    target="_self"
                    title="Produkte"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/produkte/#ZartcremeinderDose"
                        target="_self"
                      >
                        Zartcreme
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#schaumbad-und-dusche"
                        target="_self"
                      >
                        Schaumbad und Dusche
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Glanzshampoo"
                        target="_self"
                      >
                        Glanzshampoo
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Pflegelotion"
                        target="_self"
                      >
                        Pflegelotion
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#MildeHandseife"
                        target="_self"
                      >
                        Milde Handseife
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#shampoo"
                        target="_self"
                      >
                        Stärkendes Shampoo und Spülung
                      </a>
                    </li>
                    <li>
                    <a
                      href="/produkte/#Lippenpflegemit"
                      target="_self"
                    >  
                     Lippenpflege mit fruchtigem Kirschduft
                    </a>
                </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-unsere-maedels"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/unsere-maedels/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.unsere-maedels.png"
                  alt="Unsere Mädels"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/unsere-maedels/"
                    target="_self"
                    title="Unsere Mädels"
                  >
                    Unsere Mädels
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/unsere-maedels/#BibiBlubberblase"
                        target="_self"
                      >
                        Bibi Blubberblase
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#MiaMorgentau"
                        target="_self"
                      >
                        Mia Morgentau
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#PaulaPerlensanft"
                        target="_self"
                      >
                        Paula Perlensanft
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#ViviWallemaehne"
                        target="_self"
                      >
                        Vivi Wallemähne
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#SophieSonnenschein"
                        target="_self"
                      >
                        Sophie Sonnenschein
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#Malbuch"
                        target="_self"
                      >
                        Mal- &amp; Rätselbuch
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="col-marken" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/markenhistorie/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.markenhistorie.png"
                  alt="Markenhistorie"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/markenhistorie/"
                    target="_self"
                    title="Markenhistorie"
                  >
                    Markenhistorie
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a href="/markenhistorie/#60er" target="_self">
                        60er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#70er" target="_self">
                        70er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#80er" target="_self">
                        80er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#90er" target="_self">
                        90er
                      </a>
                    </li>
                    <li>
                      <a
                        href="/markenhistorie/#2000er"
                        target="_self"
                      >
                        2000er
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-limited-editions"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/limited-editions/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.limited_editions.png"
                  alt="Limited Editions"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/limited-editions/"
                    target="_self"
                    title="Limited Editions"
                  >
                    Limited Editions
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/limited-editions/#OnlineGame"
                        target="_self"
                      >
                        Online Game
                      </a>
                    </li>
                    <li>
                      <a
                        href="/limited-editions/#LimitedEditions"
                        target="_self"
                      >
                        Limited Editions
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 meganav-mobile-bottom">
              {" "}
              <a
                className="navitem"
                title="Start"
                target="_self"
                href="/"
              >
                Start
              </a>{" "}
              <a
                className="navitem"
                title="Kontakt"
                target="_self"
                href="/menu/kontakt/"
              >
                Kontakt
              </a>{" "}
              Newsletter{" "}
            </div>
          </div>
        </div>
        <div id="content">
          <div className="container">
            <div id className="row stageless mb-50">
              <div className=" col-sm-8 col-sm-offset-2">
                <h1>Cookie-Richtlinie</h1>
                <p>
                  Diese Cookie-Richtlinie verfolgt den Zweck, Sie über unsere
                  Vorgehensweisen in Bezug auf Ihre Daten aufzuklären, die wir
                  mithilfe von Cookies und anderen Tracking-Technologien
                  erfassen (z.B. Gifs, Web Beacons, etc.)
                </p>
                <p>
                  Die Funktionalitäten, für die wir diese Technologien
                  einsetzen, können Folgendes beinhalten:
                </p>
                <ul>
                  <li>Sie in der Navigation zu unterstützen, </li>
                  <li>
                    Sie dabei zu unterstützen, sich für unsere Veranstaltungen
                    zu registrieren, beim Einloggen und bei Ihren Möglichkeiten,
                    uns Feedback zu geben,
                  </li>
                  <li>
                    die Verwendung unserer Produkte, Dienstleistungen oder
                    Anwendungen zu analysieren,
                  </li>
                  <li>
                    Sie mit unseren Marketing-Aktivitäten zu unterstützen (incl.
                    interessensangepasste Werbung),
                  </li>
                  <li>
                    Inhalte von Dritten anzubieten (z.B. Social Media Inhalte).
                  </li>
                </ul>
                <p>
                  Am Ende dieser Richtlinie finden Sie eine detaillierte
                  Auflistung der Cookies mit Beschreibung. Wir klassifizieren
                  Cookies entsprechend den nachfolgenden Kategorien:
                </p>
                <ul>
                  <li>Unbedingt erforderliche Cookies</li>
                  <li>Leistungs-Cookies</li>
                  <li>Funktionelle Cookies</li>
                  <li>Targeting Cookies</li>
                </ul>
                <p>
                  Wir setzen Cookies nur, wenn das gültige Recht es ausdrücklich
                  erlaubt oder nachdem wir Ihr Einverständnis hierfür über das
                  Cookie-Banner oder über die Einstellungen erhalten haben. Sie
                  können Ihre Cookie Einstellungen für jede Kategorie zu jeder
                  Zeit verändern, indem Sie auf den Cookie-Einstellungen-Button
                  weiter unten klicken (außer für die Kategorie unbedingt
                  erforderliche Cookies, da diese zum Funktionieren der Website
                  notwendig sind):
                </p>
                <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie-Einstellungen</button>
                <br />
                <p>&nbsp;</p>
                <div id="ot-sdk-cookie-policy" />
                <h2>Links zu anderen Websites</h2>
                <p>
                  Diese Website kann Links oder Querverweise zu anderen Websites
                  enthalten. Bitte berücksichtigen Sie, dass wir die Cookies /
                  Tracking-Technologien anderer Websites nicht kontrollieren und
                  diese Cookie-Richtlinie nicht für solche Websites gilt.{" "}
                </p>
                <h2>Kontaktaufnahme</h2>
                <p>
                  Wenn Sie Fragen, Kommentare oder Bedenken bezüglich dieser
                  Cookie-Richtlinie oder der Informationspraktiken dieser
                  Website haben, nutzen Sie bitte die in der
                  Datenschutzerklärung hinterlegten Kontaktinformationen um mit
                  uns Kontakt aufzunehmen.{" "}
                </p>
                <h2>Änderungen dieser Cookie-Richtlinie</h2>
                <p>
                  Bei Änderungen dieser Cookie-Richtlinie wird die überarbeitete
                  Richtlinie auf dieser Website bekannt gegeben. Diese
                  Cookie-Richtlinie wurde zuletzt am <strong>18.05.2021</strong>{" "}
                  aktualisiert.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          src="/fileadmin/js/vendor/jquery6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/detectmobilebrowser6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/bootstrap.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.ba-throttle-debounce.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/skrollr.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/isMobile.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.scrollTo.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/script71c5.js?1527068951"
          type="text/javascript"
        />
      </div>
    );
  }
}

export default Page;
